<template>
  <Modal v-model="isShow" title="广告管理" class-name="i-modal" width="1000">
    <Form :model="obj" ref="levelForm" :rules="rules" :label-width="80">
      <Card style="margin-bottom: 8px">
        <Row :gutter="16">
          <Col span="24">
            <FormItem label="广告图片:" prop="file">
              <i-upload
                fileKey="banner"
                @onChange="onChange"
                ref="Uploadfile"
                :max="1"
              />
            </FormItem>
          </Col>
        </Row>
      </Card>
      <Row :gutter="16">
        <Col span="12">
          <FormItem prop="Title" label="广告标题">
            <Input type="text" v-model="obj.Title" />
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem prop="Summary" label="广告描述">
            <Input type="text" v-model="obj.Summary" />
          </FormItem>
        </Col>
      </Row>
      <Row :gutter="16">
        <Col span="12">
          <FormItem label="渠道:" prop="channelArr">
            <Select multiple v-model="channelArr" placeholder="渠道">
              <Option :key="'30'" :value="'30'">苹果</Option>
              <Option :key="'20'" :value="'20'">安卓</Option>
              <Option :key="'10'" :value="'10'">微信</Option>
              <Option :key="'40'" :value="'40'">小程序</Option>
            </Select>
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem prop="Status" label="状态">
            <i-select
              transfer
              v-model="obj.Status"
              clearable
              placeholder="状态"
            >
              <i-option
                v-for="item in AdvertStatusEnum"
                :value="item.ID"
                :key="item.ID"
                >{{ item.CN }}</i-option
              >
            </i-select>
          </FormItem>
        </Col>
      </Row>
      <Row v-if="showVideo" :gutter="16">
        <Col span="12">
          <FormItem prop="Type" label="广告格式">
            <i-select
              transfer
              v-model="obj.Type"
              clearable
              placeholder="广告格式"
            >
              <i-option :value="0">图片</i-option>
              <i-option :value="1">视频</i-option>
            </i-select>
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem prop="VideoPath" label="视频地址">
            <Input
              type="text"
              v-model="obj.VideoPath"
              placeholder="仅支持七牛地址(全路径)"
            ></Input>
          </FormItem>
        </Col>
      </Row>
      <Row :gutter="16">
        <Col span="12">
          <FormItem prop="Version" label="版本号:">
            <Select
              clearable
              v-model="obj.Version"
              placeholder="版本号"
              @on-change="versionChange"
            >
              <Option
                v-for="item in PositionVersionEnum"
                :key="item.ID"
                :value="item.CN"
                >{{ item.CN }}</Option
              >
            </Select>
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem prop="Position" label="广告位置">
            <i-select
              transfer
              v-model="obj.Position"
              multiple
              clearable
              filterable
              @on-change="changeposition"
              placeholder="广告位置"
              :disabled="!isEdit && !isAdd"
            >
              <i-option
                v-for="item in positionList"
                :value="String(item.Code)"
                :key="item.Code"
                >{{ item.Name }}</i-option
              >
            </i-select>
          </FormItem>
        </Col>
        <!-- <Col span="12">
        <FormItem prop="Position"
                  label="广告位置">
          <i-select transfer
                    v-model="obj.Position"
                    multiple
                    clearable
                    filterable
                    placeholder="广告位置"
                    @on-change="changeposition"
                    :disabled="!isEdit && !isAdd">
            <i-option v-for="item in AdvertPositionEnum"
                      :value="String(item.ID)"
                      :key="item.ID">{{item.CN}}</i-option>
          </i-select>
        </FormItem>
        </col> -->
      </Row>
      <Row :gutter="16">
        <Col span="12">
          <FormItem prop="Url" label="链接地址">
            <Input type="text" v-model="obj.Url"></Input>
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem prop="BeginDate" label="开始时间">
            <DatePicker
              type="datetime"
              v-model="obj.BeginDate"
              style="width: 100%"
            ></DatePicker>
          </FormItem>
        </Col>
      </Row>
      <Row :gutter="16">
        <Col span="12">
          <FormItem prop="EndDate" label="结束时间">
            <DatePicker
              type="datetime"
              v-model="obj.EndDate"
              style="width: 100%"
            ></DatePicker>
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem prop="Index" label="排序参数">
            <Input type="text" v-model="obj.Index"></Input>
          </FormItem>
        </Col>
      </Row>
      <Row :gutter="16">
        <Col span="12">
          <FormItem prop="CreateUserID" label="创建人">
            <Input type="text" v-model="obj.CreateUserName" disabled></Input>
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem prop="CreateDate" label="创建时间">
            <Input type="text" v-model="obj.CreateDate" disabled></Input>
          </FormItem>
        </Col>
      </Row>
    </Form>
    <template slot="footer">
      <Button type="default" @click="handleCancel">取消</Button>
      <Button type="primary" :loading="isUploading" @click="handleSave"
        >保存</Button
      >
    </template>
    <Modal
      title="查看大图"
      class-name="i-modal"
      :footer-hide="true"
      v-model="isShowViewImage"
    >
      <img :src="ViewImg" v-if="isShowViewImage" style="width: 100%" />
    </Modal>
  </Modal>
</template>

<script>
import api from "@/api";
import bizEnum from "@/libs/bizEnum";
import IUpload from "@/components/upload/index.vue";
export default {
  name: "advert-edit",
  async created() {
    if (this.AdvertPositionList.length === 0) {
      try {
        let res2 = await api.GetAllAdvertPosition({});
        if (res2.Data) {
          this.AdvertPositionList = res2.Data;
        }
      } catch (error) {
        this.$Notice.error({ title: "错误", desc: error });
      }
    }
  },
  data() {
    return {
      isShow: false,
      isUploading: false,
      SaveLoading: false,
      tableLoading: false,
      isAdd: false,
      isEdit: false,
      showVideo: false,
      ViewImg: "",
      isShowViewImage: false,
      Type: 0,
      tableHeight: 0,
      obj: {
        Version: "",
        Position: [],
        Channel: "",
      },
      files: [],
      AdvertPositionList: [],
      channelArr: [],
      rules: {
        Title: [{ required: true, message: "名称不能为空" }],
        Status: [{ required: true, message: "状态不能为空", type: "number" }],
        Index: [{ required: true, message: "排序参数不能为空" }],
        BeginDate: [{ required: true, message: "开始时间不能为空" }],
        EndDate: [{ required: true, message: "结束时间不能为空" }],
      },
    };
  },
  watch: {
    isShow: function (val) {
      if (val) {
        if (this.id !== -1) {
          this.files = this.$refs.Uploadfile.uploadFiles = [
            {
              _key: this.obj.ImgPath,
              _file: this.$setImagePrefix(this.obj.ImgPath),
              success: true,
            },
          ];
          if (this.obj.Channel) {
            this.channelArr = this.obj.Channel.split(",");
          } else {
            this.channelArr = [];
          }
        } else {
          this.files = this.$refs.Uploadfile.uploadFiles = [];
          this.obj.Type = 0;
          this.showVideo = false;
        }
      }
    },
  },
  mounted() {
    const self = this;
    self.$nextTick(() => {
      self.tableHeight = self.$getTableHeight("main-container");
    });
    window.onresize = function () {
      self.$nextTick(() => {
        self.tableHeight = self.$getTableHeight("main-container");
      });
    };
  },
  methods: {
    changeposition(sender) {
      if (
        sender.findIndex((item) => item === "6" && !this.obj.Frequency) >= 0
      ) {
        this.obj.Frequency = 1;
      }
      if (
        sender.findIndex((item) => item === "1") >= 0 &&
        sender.length === 1
      ) {
        this.showVideo = true;
      } else {
        this.obj.Type = 0;
        this.obj.VideoPath = "";
        this.showVideo = false;
      }
      if (sender.length > 1) {
        this.obj.Type = 0;
        this.obj.VideoPath = "";
        this.showVideo = false;
      }
    },
    onChange(opt) {
      this.files = opt.files;
    },
    versionChange() {
      this.obj.Position = [];
    },
    handleViewImg(src) {
      this.ViewImg = src;
      this.isShowViewImage = true;
    },
    handleCancel() {
      // this.$refs.levelForm.resetFields();
      this.isShow = false;
    },
    async handleSave() {
      // TODO: 提交前数据验证待优化
      const _this = this;
      const res = await _this.$refs.Uploadfile.Upload();
      if (res.success.length !== 0) {
        _this.obj.ImgPath = res.success[0];
      } else if (_this.obj.Type === 0 && res.success.length === 0) {
        _this.$Message.error("请上传图片");
        return false;
      }
      let temp = "";
      // let temp2 = '';
      let status = true;
      if (
        _this.obj.Type === 1 &&
        (!_this.obj.VideoPath || _this.obj.VideoPath.length === 0)
      ) {
        _this.$Message.error("请输入视频地址");
        return false;
      }
      if (typeof _this.obj.Position === "string") {
        _this.obj.Position = _this.obj.Position.split(",");
      }
      if (!_this.obj.Position || _this.obj.Position.length === 0) {
        _this.$Message.error("请选择广告位置");
        return false;
      }
      if (
        _this.obj.Position.findIndex((item) => item === "6") >= 0 &&
        !_this.obj.Frequency
      ) {
        _this.obj.Frequency = 1;
      }
      _this.obj.Position.map((item) => {
        if (status) {
          temp = temp + item;
          status = false;
        } else {
          temp = temp + "," + item;
        }
      });
      _this.obj.Position = temp;
      if (_this.obj.IsPreview && !_this.obj.PreviewMin) {
        _this.$Message.error("请输入提前预告时间");
        return false;
      }
      if (!_this.channelArr || _this.channelArr.length === 0) {
        _this.$Message.error("请选择渠道");
        return false;
      }
      this.obj.Channel = _this.channelArr.toString();
      _this.$refs.levelForm.validate(async (valid) => {
        if (valid) {
          try {
            this.isUploading = true;
            await api.SaveAdvert({ entity: _this.obj });
            this.$Message.success("保存成功");
            this.isUploading = false;
            this.isShow = false;
            setTimeout(function () {
              _this.$emit("on-save", this.obj);
            }, 800);
          } catch (err) {
            this.isUploading = false;
            this.$Notice.error({
              title: "错误",
              desc: err,
            });
          }
        }
      });
    },
  },
  computed: {
    positionList() {
      return this.obj.Version
        ? this.AdvertPositionList.filter(
            (item) => item.Version === this.obj.Version
          )
        : this.AdvertPositionList;
    },
    AdvertPositionEnum() {
      return bizEnum.AdvertPositionEnum;
    },
    AdvertStatusEnum() {
      return bizEnum.AdvertStatusEnum;
    },
    AdvertFrequencyEnum() {
      return bizEnum.AdvertFrequencyEnum;
    },
    PositionVersionEnum() {
      return bizEnum.PositionVersionEnum;
    },
  },
  components: { IUpload },
};
</script>

<style lang="scss"></style>
